/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BackgroundImg from "../components/BackgroundImg";
import Sectionblocklarge from "../components/SectionBlockLarge";
import Sectionblockmargin from "../components/SectionBlockMargin";

export const BrandPageTemplate = ({
  heroimagetitle,
  heroalt,
  heroimage,
  title,
  alt,
  image,
  section,
  content,
}) => {
  return (
    <section>
      {heroimage ? (
        <BackgroundImg
          imageInfo={{
            image: heroimage,
            alt: heroalt,
            title: heroimagetitle,
          }}
        />
      ) : null}

      <div sx={{ pt: [0, 4], pb: [4, 5] }}>
        <Sectionblockmargin
          content={content}
          title={title}
          alt={alt}
          image={image}
        />
      </div>
      {section?.map((section) => {
        let link;
        if (section.landingpagelink === "true") {
          link = "/";
        } else if (section.projectspagelink === "true") {
          link = "/projects";
        } else if (section.productspagelink === "true") {
          link = "/products";
        } else if (section.servicespagelink === "true") {
          link = "/services";
        } else if (section.productlink === "true") {
          link = `/products/${section.productlink}`;
        } else if (section.projectlink === "true") {
          link = `/projects/${section.projectlink}`;
        }
        return (
          <div key={section.title}>
            <Sectionblocklarge
              imagegallery={section.imagegallery}
              title={section.title}
              text={section.body}
              href={link}
              linktext={section.linktitle}
            />
          </div>
        );
      })}
    </section>
  );
};

BrandPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const BrandPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <BrandPageTemplate
        content={post.html}
        heroimagetitle={post.frontmatter.heroimagetitle}
        heroimage={post.frontmatter.heroimage}
        heroalt={post.frontmatter.heroalt}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        alt={post.frontmatter.alt}
        section={post.frontmatter.section}
      />
    </Layout>
  );
};

BrandPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BrandPage;

export const brandPageQuery = graphql`
  query BrandPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "brand-page" } }) {
      id
      html
      frontmatter {
        heroimagetitle
        heroalt
        heroimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        title
        alt
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        section {
          title
          body
          linktitle
          imagegallery {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          linktitle
          productlink
          projectlink
          projectspagelink
          productspagelink
          landingpagelink
          servicespagelink
        }
      }
    }
  }
`;
